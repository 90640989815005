import {
  SET_BANNER_HEADER_LABEL, SET_MENU_INDEX, SET_SUB_MENU_INDEX, SET_TRACKING_CODE, SET_TRACKING_LOADING, SET_IS_MOBILE,
} from '../types/types';

const INITIAL_STATE = {
  trackingCode: {},
  herderLabel: '',
  trackingLoading: false,
  subMenuIndex: 0,
  menuIndex: 0,
};

const trackingReducer = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_TRACKING_CODE:
    return {
      ...state, trackingCode: action.payload,
    };
  case SET_BANNER_HEADER_LABEL: {
    return {
      ...state, herderLabel: action.payload,
    };
  }
  case SET_SUB_MENU_INDEX:
    return {
      ...state, subMenuIndex: action.payload,
    };
  case SET_MENU_INDEX:
    return {
      ...state, menuIndex: action.payload,
    };
  case SET_TRACKING_LOADING:
    return {
      ...state, trackingLoading: action.payload,
    };
  case SET_IS_MOBILE:
    return {
      ...state, isMobile: action.payload,
    };
  default:
    return state;
  }
};

export default trackingReducer;
