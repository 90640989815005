import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getShortsAPI } from '../../api/short';
import { userMemberDataType } from '../../constant';
import { setIsOpenDrawer, setShowFormData } from '../../redux/actions/formAction';
import { RootState } from '../../redux/store';
import PRIVILEGES from '../../utils/privilege';
import { Mode, Role } from '../../utils/types/role';
import { AddButton, CustomerContainer, UserContainer } from '../common/styles';
import { SearchContainer } from '../ListingComponent/styles';
import ShortsModal from './components/ShortsModal';
import ShortsTableComponent from './components/ShortsTableComponent';
import { ShortCategory } from './ShortCategory/ShortCategoriesComponent';

export interface Short {
  _id: string;
  title: string;
  videoLink: string;
  thumbnail: string;
  categories: ShortCategory[];
  relatedShorts: Short[];
  status: 'Draft' | 'Archived' | 'Published';
}

const ShortComponent = () => {
  const dispatch = useDispatch();
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [shorts, setShorts] = useState<Short[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0
  });

  const canCreate = PRIVILEGES.Shorts.Create?.includes(currentLoginUser.type);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchShorts(searchTerm);
      setIsLoading(false);
    })();
  }, []);

  const fetchShorts = async (searchTerm) => {
    const res = await getShortsAPI(pagination, searchTerm);
    const { data, count } = res;
    setShorts(data);
    setPagination({
      ...pagination,
      total: count
    });
  };

  const openDrawar = (e: any) => {
    e.stopPropagation();

    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Short'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onPageChange = (page: number, pageSize?: number) => {
    const newPagination = { ...pagination, page, pageSize: pageSize || pagination.pageSize };
    setPagination(newPagination);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (!value) {
      fetchShorts(value);
    }
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <UserContainer>
          {canCreate && (
            <AddButton type="primary" onClick={openDrawar}>
              <PlusOutlined />
            </AddButton>
          )}
          <ShortsModal onSubmit={() => fetchShorts(searchTerm)} />
        </UserContainer>

        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <div className="flex justify-end gap-x-3 items-center mr-5">
                {currentLoginUser.type === Role.Admin && (
                  <>
                    <Link
                      to="/dashboard/shorts/levels"
                      className="rounded border-w-2 border border-solid p-1"
                    >
                      View Levels
                    </Link>
                    <Link
                      to="/dashboard/shorts/categories"
                      className="rounded border-w-2 border border-solid p-1"
                    >
                      View Categories
                    </Link>
                  </>
                )}
                <SearchContainer className="!my-0 search-box">
                  <Button onClick={() => fetchShorts(searchTerm)} className="SearchButton">
                    <SearchOutlined />
                  </Button>
                  <Input
                    onPressEnter={() => fetchShorts(searchTerm)}
                    name="search"
                    value={searchTerm}
                    onChange={handleChange}
                    placeholder="Search short"
                  />
                </SearchContainer>
              </div>
              <ShortsTableComponent className="resources-table" shorts={shorts} />
              <Pagination
                current={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={onPageChange}
                className="my-pagination"
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ShortComponent;
